import {
  EnhancedStore,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import AuthReducer from "./auth-slice";
import { useDispatch, useSelector } from "react-redux";
import AppstateSlice from "./appstate-slice";

const rootReducer = combineReducers({
  authSlice: AuthReducer,
  appStateSlice: AppstateSlice,
});

export let store: EnhancedStore;

export const setupStore = (preloadedState?: Partial<RootState>) => {
  if (
    store !== undefined &&
    (preloadedState === undefined || Object.keys(preloadedState).length === 0)
  )
    return store;
  store = configureStore({
    reducer: rootReducer,
    preloadedState,
  });

  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
