import pjson from "../../../../../package.json";

export interface WatermarkProps {
  env?: string;
}

export const Watermark = ({ env }: WatermarkProps) => (
  <div className="text-right">
    {env && (
      <>
        <span className="text-sm font-semibold">Env:</span>
        <span className="font-thin">{env} &nbsp;</span>
      </>
    )}
    <span className="text-sm font-semibold">Vers:</span>
    <span className="font-thin">{pjson.version}</span>
  </div>
);
