import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { AppRoutes } from "./components/routes/AppRoutes";
import { useAppSelector } from "./store/store";

function App() {
  const isAuth = useAppSelector((state) => state.authSlice.isAuth);

  console.log("RENDER - App");
  return <BrowserRouter>{isAuth ? <AppRoutes /> : <></>}</BrowserRouter>;
}

export default App;
