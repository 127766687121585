/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NostroCategoryCreateDto } from "../models/NostroCategoryCreateDto";
import type { NostroCategoryDto } from "../models/NostroCategoryDto";
import type { NostroCategoryUpdateDto } from "../models/NostroCategoryUpdateDto";
import type { PagedModelEntityModelNostroCategoryDto } from "../models/PagedModelEntityModelNostroCategoryDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class NostroCategoryControllerService {
  /**
   * Returns a paged list of NostroCategoryDto entities
   * @returns PagedModelEntityModelNostroCategoryDto Paged list of NostroCategoryDto entities
   * @throws ApiError
   */
  public static getAllNostroCategories({
    page,
    size = 10,
    sort,
    search,
    code,
    comment,
    id,
    text,
    status,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    code?: string;
    comment?: string;
    id?: number;
    text?: string;
    status?: string;
  }): CancelablePromise<PagedModelEntityModelNostroCategoryDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos-cat",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        code: code,
        comment: comment,
        id: id,
        text: text,
        status: status,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Add new User entity
   * @returns PagedModelEntityModelNostroCategoryDto Nostro Category successfully created
   * @throws ApiError
   */
  public static createNostroCategory({
    requestBody,
  }: {
    requestBody: NostroCategoryCreateDto;
  }): CancelablePromise<PagedModelEntityModelNostroCategoryDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/nos-cat",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Returns a NostroCategoryDto entity
   * @returns NostroCategoryDto NostroCategoryDto entity
   * @throws ApiError
   */
  public static getNostroCategory({
    nostroCategoryPathId,
  }: {
    nostroCategoryPathId: number;
  }): CancelablePromise<NostroCategoryDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos-cat/{nostroCategoryPathId}",
      path: {
        nostroCategoryPathId: nostroCategoryPathId,
      },
      errors: {
        403: `Unauthorized user`,
        404: `Record category not found`,
      },
    });
  }

  /**
   * Updates a specific Nostro to find using the NostroCategoryId sent
   * @returns PagedModelEntityModelNostroCategoryDto Nostro Category updated
   * @throws ApiError
   */
  public static updateNostroCategory({
    nostroCategoryPathId,
    requestBody,
  }: {
    nostroCategoryPathId: number;
    requestBody: NostroCategoryUpdateDto;
  }): CancelablePromise<PagedModelEntityModelNostroCategoryDto> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/api/nos-cat/{nostroCategoryPathId}",
      path: {
        nostroCategoryPathId: nostroCategoryPathId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
        404: `Nostro Category record not found`,
      },
    });
  }
}
