interface PaperProps {
  children: JSX.Element;
}
export const Paper = ({ children }: PaperProps) => {
  return (
    <div
      style={{
        padding: "8px",
        backgroundColor: "white",
        minHeight: "646px",
        boxShadow:
          "0px 1px 2px rgba(103, 104, 113, 0.24),0px 1px 3px rgba(103, 104, 113, 0.12)",
      }}
    >
      {children}
    </div>
  );
};
