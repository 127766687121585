import { FC, PropsWithChildren, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { appStateActions } from "../../store/appstate-slice";

const CleanState: FC<PropsWithChildren> = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("route to", location.pathname);
    dispatch(appStateActions.error(null)); // every time the route changes clean the state
    // if you don't use redux-toolkit you can use action.payload etc....
  }, [location.pathname, dispatch]);

  return <>{props.children}</>;
};

export default CleanState;
