import { createSlice } from "@reduxjs/toolkit";

export type AppError = {
  code: number;
  message: string;
  function: string;
};

export interface AppState {
  error?: AppError;
}

const initAppState: AppState = {};

const AppStateSlice = createSlice({
  name: "appstate-slice",
  initialState: initAppState,
  reducers: {
    error(state, actions) {
      console.log("dispatched error message:", actions.payload);
      state.error = actions.payload;
    },
  },
});

export const appStateActions = AppStateSlice.actions;

export default AppStateSlice.reducer;
