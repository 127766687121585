/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportRequest } from "../models/ExportRequest";
import type { ReportResponse } from "../models/ReportResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ExportControllerService {
  /**
   * Create publication
   * @returns ReportResponse Publication successfully created
   * @throws ApiError
   */
  public static createExport({
    requestBody,
  }: {
    requestBody: ExportRequest;
  }): CancelablePromise<ReportResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/export",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        403: `Unauthorized user`,
      },
    });
  }

  /**
   * Download publication created
   * @returns binary Publication successfully downloaded
   * @throws ApiError
   */
  public static download({
    filename,
  }: {
    filename: string;
  }): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/export/download",
      query: {
        filename: filename,
      },
      errors: {
        403: `Unauthorized user`,
      },
    });
  }
}
