import { useEffect, useRef } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  ExportControllerService,
  JobControllerService,
  PagedModelEntityModelJobLogDto,
  ReportResponse,
} from "../../openapi";
import ConfirmModal from "./ConfirmModal";
import { downloadCall } from "../../utils/downloadCallUtils";
import { store } from "../../store/store";

const dialogContents = {
  "import-account-balance": {
    title: "Confirmation",
    body: "Confirm import of account balance?",
    confirm: true,
  },
  "export-nos-vs-acc-bal": {
    title: "Confirmation",
    body: "Confirm export of Nostro vs Account Balance?",
    confirm: true,
  },
  noAction: {
    title: "",
    body: "",
    confirm: false,
  },
};

const RenderModal = ({
  action,
  setOnClickAction,
  setComponentPanelFetching,
}: {
  action: "import-account-balance" | "export-nos-vs-acc-bal" | undefined;
  setOnClickAction: any;
  setComponentPanelFetching: any;
}) => {
  let dialogContent = dialogContents[!!action ? action : "noAction"];

  const dialog = useRef<HTMLDialogElement>();

  useEffect(() => {
    if (action) {
      dialog.current && dialog.current.showModal();
    }
  }, [action]);

  function closeDialog() {
    console.log("closeDialog..");
    setOnClickAction(undefined);
    dialog.current && dialog.current.close();
  }

  function importAccountBalance() {
    setComponentPanelFetching(true);
    authorizedCall(JobControllerService.restartJob, {
      jobName: "IMPORT_ACC_BAL",
    })
      .then(() => {
        authorizedCall(
          JobControllerService.getLastJobRun,
          { jobName: "IMPORT_ACC_BAL" },
          true
        ).then((resp: PagedModelEntityModelJobLogDto) => {
          const list = resp._embedded?.entities;
          console.info("DEBUG - getLastJobRun:", list && list[0]);
          const textBody =
            "Account balance successfully imported at " + list![0].createTs;
          dialogContent = {
            title: "Result",
            body: textBody,
            confirm: false,
          };
          dialog.current && dialog.current.showModal();
        });
      })
      .finally(() => {
        setComponentPanelFetching(false);
      });
  }

  function exportNostroVsAccountBalance() {
    setComponentPanelFetching(true);
    authorizedCall(
      ExportControllerService.createExport,
      {
        requestBody: {
          type: "NOS_VS_ACC_BAL",
          timestamp: new Date().toISOString(),
        },
      },
      true
    )
      .then((resp: ReportResponse) => {
        console.log("response", resp);
        const token = store.getState().authSlice.authTokens?.accessToken;
        const id = resp?.reportId;
        console.log("reportId", id);
        const DOWNLOAD_URL = `${process.env.REACT_APP_API_URL}/api/export/download?filename=${id}`;

        downloadCall(DOWNLOAD_URL, token, id).then(() => {
          const textBody = "Export file " + id + " successfully downloaded";
          dialogContent = {
            title: "Result",
            body: textBody,
            confirm: false,
          };
          dialog.current && dialog.current.showModal();
        });
      })
      .finally(() => {
        setComponentPanelFetching(false);
      });
  }

  function confirmDialog() {
    console.log("confirmDialog..");
    dialog.current && dialog.current.close();

    switch (action) {
      case "import-account-balance":
        importAccountBalance();
        break;
      case "export-nos-vs-acc-bal":
        exportNostroVsAccountBalance();
    }
  }

  return (
    <ConfirmModal
      content={dialogContent}
      onClose={closeDialog}
      onConfirm={confirmDialog}
      ref={dialog}
    />
  );
};

export default RenderModal;
