import {
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import { useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelJobLogDto,
  EntityModelNostroOverviewDto,
  JobControllerService,
  NostroControllerService,
  PagedModelEntityModelJobLogDto,
} from "../../openapi";
import { useNavigate } from "react-router-dom";

export const AccountView = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);
  const [selected, setSelected] = useState<
    EntityModelNostroOverviewDto | undefined
  >(undefined);

  const [accFetching, setAccFetching] = useState<boolean>(false);

  const [lastImportAcc, setLastImportAcc] = useState<EntityModelJobLogDto>();

  //const [lastImport, setLastImport] = useState<EntityModelAuditDto>();

  //const dispatch = useAppDispatch();

  async function dataTableApiCall(url: any, config: any, params: any) {
    console.log("dataTableCall");

    setAccFetching(true);
    const sortArray: Array<string> =
      params.sort.length > 0 ? [params.sort[0].field, params.sort[0].dir] : [];
    const apiParams = {
      page: params.page,
      size: params.size,
      sort: sortArray,
      search: searchFilter.current?.value,
    };
    return authorizedCall(NostroControllerService.getAllNostro, apiParams, true)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        setAccFetching(false);
      });
  }

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "ID", field: "id" },
      { title: "Number", field: "number" /*, headerFilter: 'input'*/ },
      { title: "Name", field: "name" },
      { title: "Category", field: "" },
      { title: "Risk Compl", field: "riskComplianceCode" },
      { title: "Risk Fraud", field: "riskFraudCode" },
      { title: "KUBE UserID", field: "upn" },
      { title: "Status", field: "status" },
      { title: "KSTA CHF", field: "busaldoChfBr" },
      { title: "KSTA Ccy", field: "kstaCcyBr" },
      { title: "Bu Saldo CHF", field: "busaldoChfBr" },
      { title: "Bu Saldo Ccy", field: "busaldoCcyBr" },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };

    getLastImportAcc();
  }, []);

  function refreshTable(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function rowSelectionChanged(row: any) {
    //row - row component
    const selected: EntityModelNostroOverviewDto = row.detail.data[0];
    setSelected(selected);
    console.log("rowSelectionChanged: ", selected);
  }

  function updateAllAcc() {
    setAccFetching(true);
    authorizedCall(JobControllerService.restartJob, {
      jobName: "IMPORT_ACC_BAL",
    })
      .then(() => {
        setAccFetching(false);
        getLastImportAcc();
        dataTable.current!.options = { ...dataTable.current!.options };
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        //setAccFetching(false);
      });
  }

  function getLastImportAcc() {
    setAccFetching(true);
    authorizedCall(
      JobControllerService.getLastJobRun,
      { jobName: "IMPORT_ACC_BAL" },
      true
    )
      .then((resp: PagedModelEntityModelJobLogDto) => {
        console.log(resp);
        if (resp._embedded?.entities)
          setLastImportAcc(resp._embedded.entities[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //setAccFetching(false);
      });
  }

  console.log("RENDER - AccountView [accFetching:", accFetching, "]");

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "View ACC" },
      ]}
    >
      <div className="content p-4 bg-white">
        <div style={{ borderBottom: "1px solid #ddd" }}>
          <div className="flex flex-row pb-6">
            <div className="basis-1/2">
              <p
                className="heading--two text-xl"
                data-testid="view-relationship-manager-pagetitle"
              >
                View ACC
              </p>
            </div>
            <div className="basis-1/4">
              <ZfSearchField
                placeholder="Search ..."
                onInputChange={(e) => {
                  refreshTable(e);
                }}
                ref={searchFilter}
              ></ZfSearchField>
            </div>

            <div className="basis-1/2 flex flex-row-reverse">
              <ZfTextButton
                disabled={selected === undefined}
                onClick={() => navigate(`/acc-view/${selected?.id}`)}
                hierarchy="primary"
                className="mr-1"
              >
                Edit
              </ZfTextButton>
              <ZfTextButton
                hierarchy="secondary"
                disabled={accFetching === true}
                onClick={updateAllAcc}
                className="mr-2"
              >
                Update ACC
              </ZfTextButton>
              <ZfTextButton
                hierarchy="secondary"
                disabled={accFetching === true}
                className="mr-2"
              >
                Archive deleted
              </ZfTextButton>
              <ZfIconButton
                hierarchy="secondary"
                icon="arrow_back"
                className="mr-2"
                onClick={() => navigate(`/`)}
              ></ZfIconButton>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-3">
          <label className="font-semibold">Last import by:</label>
          <label className="font-light pl-2">{lastImportAcc?.createUser}</label>
          <label className="font-semibold ml-2">at:</label>
          <label className="font-light pl-2">{lastImportAcc?.createTs}</label>
        </div>
        {error && <p className="error">Error in loading data</p>}
        {accFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) => tableBuiltHandler(event, setAccFetching)}
          onTableRowSelectionChanged={rowSelectionChanged}
          className="mt-5"
          data-testid="vrm-data-table"
          style={{
            pointerEvents: accFetching && "none",
            opacity: accFetching && 0.7,
          }}
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};
