/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NostroCommentStatusDto } from "../models/NostroCommentStatusDto";
import type { PagedModelEntityModelNostroCommentStatusDto } from "../models/PagedModelEntityModelNostroCommentStatusDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class NostroCommentStatusControllerService {
  /**
   * Returns a paged list of NostroCommentStatusDto entities
   * @returns PagedModelEntityModelNostroCommentStatusDto Paged list of NostroCommentStatusDto entities
   * @throws ApiError
   */
  public static getAllNostroCommentStatus({
    page,
    size = 10,
    sort,
    search,
    code,
    simpleStatus,
    id,
    text,
    status,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    code?: string;
    simpleStatus?: string;
    id?: number;
    text?: string;
    status?: string;
  }): CancelablePromise<PagedModelEntityModelNostroCommentStatusDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos-com-stat",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        code: code,
        simpleStatus: simpleStatus,
        id: id,
        text: text,
        status: status,
      },
    });
  }

  /**
   * Returns a NostroCommentStatusDto entity
   * @returns NostroCommentStatusDto NostroCommentStatusDto entity
   * @throws ApiError
   */
  public static getNostroCommentStatus({
    nostroCommentPathId,
  }: {
    nostroCommentPathId: number;
  }): CancelablePromise<NostroCommentStatusDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos-com-stat/{nostroCommentPathId}",
      path: {
        nostroCommentPathId: nostroCommentPathId,
      },
      errors: {
        404: `Record not found`,
      },
    });
  }
}
