import env from "../config/env";
import { Authentication } from "./auth-slice";

export type OidcConfigProps = {
  loginUri?: string;
  authorizePath?: string;
  clientId?: string;
  redirecUri?: string;
  scope?: string;
  codeVerifier?: string;
  codeChallenge?: string;
  codeChallengeMethod?: string;
  tokenPath?: string;
  logoutPath?: string;
  idTokenHintParameterKey?: string;
  postLogoutRedirectUriParamKey?: string;
  postLogoutRedirectPath?: string;
};

export const oidcConfigProps: OidcConfigProps = {
  loginUri: "",
  authorizePath: "/oauth2/authorize/",
  clientId: "",
  redirecUri: "",
  scope: "User.Read+email+openid+offline+profile",
  tokenPath: "/oauth2/token",
  logoutPath: "/oauth2/logout",
  idTokenHintParameterKey: "id_token_hint",
  postLogoutRedirectUriParamKey: "post_logout_redirect_uri",
  postLogoutRedirectPath: "",
};

export function evaluateOidcConfigProps() {
  oidcConfigProps.loginUri = env.REACT_APP_ADFS_AUTHORITY;
  oidcConfigProps.clientId = env.REACT_APP_ADFS_CLIENT_ID;
  oidcConfigProps.redirecUri = env.REACT_APP_ADFS_REDIRECT_URL;
  oidcConfigProps.postLogoutRedirectPath = env.REACT_APP_URL + "/loggedout";
}

export function calculateAdfsUrl() {
  return (
    oidcConfigProps.loginUri! +
    oidcConfigProps.authorizePath +
    "?client_id=" +
    oidcConfigProps.clientId +
    "&redirect_uri=" +
    oidcConfigProps.redirecUri +
    "&response_type=code" +
    "&scope=" +
    oidcConfigProps.scope
  );
}

export async function authorize() {
  const location = window.location.href;
  const code = location.substring(location.indexOf("code=") + 5);
  const payload =
    "grant_type=authorization_code" +
    "&code=" +
    code +
    "&redirect_uri=" +
    oidcConfigProps.redirecUri +
    //+ '&code_verifier=' + oidcConfigProps.codeVerifier
    "&client_id=" +
    oidcConfigProps.clientId;
  //console.log('payload:', payload)

  const authorizePromise = await fetch(
    oidcConfigProps.loginUri! + oidcConfigProps.tokenPath,
    {
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  return authorizePromise;
}

export function navigateToLocationHref(redirectTo: string) {
  console.log("navigateToLocationHref to", redirectTo);
  window.location.href = redirectTo;
}

export async function refresh(refreshToken?: string) {
  const payload =
    "grant_type=refresh_token" +
    "&refresh_token=" +
    refreshToken +
    "&redirect_uri=" +
    oidcConfigProps.redirecUri +
    //+ '&code_verifier=' + oidcConfigProps.codeVerifier
    "&client_id=" +
    oidcConfigProps.clientId +
    "&scope=" +
    oidcConfigProps.scope;

  const refresh = await fetch(
    oidcConfigProps.loginUri! + oidcConfigProps.tokenPath,
    {
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return refresh;
}

export function userInfo() {
  const authTokens: Authentication = readAuthToken();
  if (authTokens === undefined) return;
  const headers = { Authorization: "Bearer " + authTokens.accessToken };
  fetch(oidcConfigProps.loginUri + "/userinfo", { headers: headers })
    .then((response2) => {
      if (!response2.ok) {
        throw new Error("Failed to obtain token");
      }
      response2.json().then((content) => {
        console.log("userinfo: " + content);
      });
    })
    .catch((error: any) => {
      console.log(error);
    });
}

export function readAuthToken(): Authentication {
  const authTokens: Authentication = JSON.parse(
    localStorage.getItem("authTokens") || "{}"
  );
  return authTokens;
}

export function checkForTokens() {
  const authTokens: Authentication = readAuthToken();
  return authTokens?.accessToken !== undefined ? true : false;
}

export function toLoginPage() {
  localStorage.removeItem("authTokens");
  const loginUrl = calculateAdfsUrl();
  //alert(loginUrl);
  window.location.href = loginUrl;
}

export function logout(idToken?: string) {
  localStorage.removeItem("authTokens");

  window.location.href =
    oidcConfigProps.loginUri! +
    oidcConfigProps.logoutPath +
    "?" +
    oidcConfigProps.idTokenHintParameterKey +
    "=" +
    idToken +
    "&" +
    oidcConfigProps.postLogoutRedirectUriParamKey +
    "=";
  //+ oidcConfigProps.postLogoutRedirectUri;
}

export function readUpn(idToken?: string) {
  const splitted = idToken?.split(".");
  const decoded = atob(splitted![1]);
  // console.log("atob:\n", decoded);
  const upn = JSON.parse(decoded).upn;
  return upn;
}
