import { ZfLoadingSpinner, ZfTable, ZfTextButton } from "@ccx/zafire-react";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import { FC, useEffect, useRef, useState } from "react";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelNostroOverviewDto,
  NostroControllerService,
} from "../../openapi";
import { useNavigate } from "react-router-dom";

export const AccountViewEditComments: FC<{ accId: number }> = (props) => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const dataTable = useRef<HTMLZfTableElement>(null);
  const [selected, setSelected] = useState<
    EntityModelNostroOverviewDto | undefined
  >(undefined);

  const [commFetching, setCommFetching] = useState<boolean>(false);

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      {
        formatter: "zfRadioRowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 50,
      },
      { title: "Status", field: "nostroCommentStatusText" },
      { title: "User Name", field: "createUser" /*, headerFilter: 'input'*/ },
      { title: "Date", field: "createTs" },
      { title: "Show on report", field: "showOnReport" },
      { title: "File link", field: "link" },
      { title: "Comment", field: "comment" },
    ];

    const actionColumns: [] = [];

    async function dataTableApiCall(url: any, config: any, params: any) {
      console.log("dataTableCall");

      setCommFetching(true);
      //const sortArray: Array<string> = params.sort.length > 0 ? [params.sort[0].field, params.sort[0].dir] : [];
      const apiParams = {
        nostroPathId: props.accId,
        page: params.page,
        size: params.size,
      };
      return authorizedCall(
        NostroControllerService.getAllCommentsForNostro,
        apiParams,
        true
      )
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          /*do nothing*/
        })
        .finally(() => {
          setCommFetching(false);
        });
    }

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, [props.accId]);

  /*function refreshTable(event: any) {
        dataTable.current!.options = { ...dataTable.current!.options };
    }*/

  function rowSelectionChanged(row: any) {
    //row - row component
    const selected: EntityModelNostroOverviewDto = row.detail.data[0];
    setSelected(selected);
    console.log("rowSelectionChanged: ", selected);
  }

  console.log("RENDER - AccountView [commFetching:", commFetching, "]");

  return (
    <>
      <div>
        <div className="flex flex-row-reverse">
          <ZfTextButton
            hierarchy="primary"
            onClick={() => navigate(`/acc-comment-edit/${props.accId}/new`)}
          >
            Create
          </ZfTextButton>
          <ZfTextButton
            disabled={selected === undefined}
            onClick={() =>
              navigate(`/acc-comment-edit/${props.accId}/${selected?.id}`)
            }
            hierarchy="secondary"
            className="mr-1"
          >
            Edit
          </ZfTextButton>
        </div>
      </div>

      {error && <p className="error">Error in loading data</p>}
      {commFetching && (
        <ZfLoadingSpinner
          size="large"
          color="primary"
          type="infinite"
          style={{
            position: "absolute",
            left: "50%",
            right: "50%",
            zIndex: "100",
            top: "35%",
          }}
          data-testid="spinner"
        ></ZfLoadingSpinner>
      )}

      <ZfTable
        ref={dataTable}
        onTableBuilt={(event) => tableBuiltHandler(event, setCommFetching)}
        onTableRowSelectionChanged={rowSelectionChanged}
        className="mt-5"
        data-testid="vrm-data-table"
        style={{
          pointerEvents: commFetching && "none",
          opacity: commFetching && 0.7,
        }}
      ></ZfTable>
    </>
  );
};
