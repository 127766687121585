/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionModelUserActionDto } from "../models/CollectionModelUserActionDto";
import type { PagedModelEntityModelComponentPropertiesDto } from "../models/PagedModelEntityModelComponentPropertiesDto";
import type { PagedModelEntityModelGrantAndFunctionDto } from "../models/PagedModelEntityModelGrantAndFunctionDto";
import type { UserActionRequest } from "../models/UserActionRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AppControllerService {
  /**
   * Trace a relevant user action on the db for audit purpose, like an insert, update, or something else
   * @returns CollectionModelUserActionDto The traced user action with its assigned id
   * @throws ApiError
   */
  public static addUserAction({
    requestBody,
  }: {
    requestBody: UserActionRequest;
  }): CancelablePromise<CollectionModelUserActionDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/user-action",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * It will execute the cleanup of temporary generated user files and return a list containing them
   * @returns string List of deleted files
   * @throws ApiError
   */
  public static cleanUpUserFiles(): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/cleanup",
    });
  }

  /**
   * Get all the grants and functions related to a role
   * @returns PagedModelEntityModelGrantAndFunctionDto A list of grants and a list of functions related to the role
   * @throws ApiError
   */
  public static getGrantsAndFunctions({
    page,
    size = 10,
    sort,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
  }): CancelablePromise<PagedModelEntityModelGrantAndFunctionDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/grants-funcs",
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    });
  }

  /**
   * Get all components and their properties to be mapped by front-end related to a role
   * @returns PagedModelEntityModelComponentPropertiesDto A list of components and their properties to be mapped by front-end related to a role
   * @throws ApiError
   */
  public static getComponentProperties({
    page,
    size = 10,
    sort,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
  }): CancelablePromise<PagedModelEntityModelComponentPropertiesDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/component-props",
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    });
  }
}
