import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { UserPanel } from "../../features/user/components/UserPanel";

export const Home = () => {
  return (
    <AuthenticatedLayout
      breadcrumbList={[{ name: "ACTION PANEL", route: "/" }]}
    >
      <UserPanel />
    </AuthenticatedLayout>
  );
};
