import type { ReactNode } from "react";

export interface PageLayoutProps {
  children: ReactNode;
}

export const PageLayout = (props: PageLayoutProps) => {
  const { children } = props;

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        className="flex-grow"
        style={{
          height: "100vh",
        }}
      >
        <div style={{ marginBottom: "4px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
