/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NostroRiskDto } from "../models/NostroRiskDto";
import type { PagedModelEntityModelNostroRiskDto } from "../models/PagedModelEntityModelNostroRiskDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class NostroRiskFraudControllerService {
  /**
   * Returns a paged list of NostroRiskFraudDto entities
   * @returns PagedModelEntityModelNostroRiskDto Paged list of NostroRiskFraudDto entities
   * @throws ApiError
   */
  public static getAllNostroRiskFraud({
    page,
    size = 10,
    sort,
    search,
    code,
    id,
    text,
    status,
  }: {
    /**
     * One-based page index (1..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    search?: string;
    code?: string;
    id?: number;
    text?: string;
    status?: string;
  }): CancelablePromise<PagedModelEntityModelNostroRiskDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos-rf",
      query: {
        page: page,
        size: size,
        sort: sort,
        search: search,
        code: code,
        id: id,
        text: text,
        status: status,
      },
    });
  }

  /**
   * Returns a NostroRiskFraudDto entity
   * @returns NostroRiskDto NostroRiskFraudDto entity
   * @throws ApiError
   */
  public static getNostroRiskFraud({
    nostroRiskFraudPathId,
  }: {
    nostroRiskFraudPathId: number;
  }): CancelablePromise<NostroRiskDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/nos-rf/{nostroRiskFraudPathId}",
      path: {
        nostroRiskFraudPathId: nostroRiskFraudPathId,
      },
      errors: {
        404: `Record not found`,
      },
    });
  }
}
