import {
  ZfIconButton,
  ZfLoadingSpinner,
  ZfSearchField,
  ZfTable,
  ZfTextButton,
} from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import authorizedCall from "../../utils/authorizedCallUtils";
import {
  EntityModelAuditDto,
  RelationshipManagerControllerService,
} from "../../openapi";

const ViewRelationshipManager = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const searchFilter = useRef<HTMLZfSearchFieldElement>(null);
  const dataTable = useRef<HTMLZfTableElement>(null);
  //const [selected, setSelected] = useState<EntityModelRelationshipManagerDto | undefined>(undefined);

  const [relManagerFetching, setRelManagerFetching] = useState<boolean>(false);

  const [lastImport, setLastImport] = useState<EntityModelAuditDto>();

  //const dispatch = useAppDispatch();

  async function dataTableApiCall(url: any, config: any, params: any) {
    console.log("dataTableCall");

    setRelManagerFetching(true);
    const sortArray: Array<string> =
      params.sort.length > 0 ? [params.sort[0].field, params.sort[0].dir] : [];
    const apiParams = {
      page: params.page,
      size: params.size,
      search: searchFilter.current?.value,
      sort: sortArray,
    };
    return authorizedCall(
      RelationshipManagerControllerService.getAllRelationshipManagers,
      apiParams,
      true
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        setRelManagerFetching(false);
      });
  }

  function cellEditedHandler(cell: any, table: any) {
    const statuses = [
      { id: 0, val: "" },
      { id: 1, val: "check" },
      { id: 2, val: "del" },
      { id: 3, val: "new" },
    ];
    //cell - cell component
    const data = cell.getData();
    const value = cell.getValue();
    const initValue = cell.getInitialValue();

    if (value === undefined || value.trim() === "") {
      cell.setValue(initValue);
    }

    const statusId = statuses.filter((s) => s.val === cell.getValue())[0].id;

    setRelManagerFetching(true);
    authorizedCall(
      RelationshipManagerControllerService.updateRelationshipManager,
      {
        relationshipManagerPathId: data.id,
        requestBody: { status: statusId },
      }
    )
      .then(() => {
        //setRelManagerFetching(false);
        //table.setPageSize(options.paginationSize);
        table.setData();
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        //setRelManagerFetching(false);
      });
  }

  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      /*{
                "formatter": "zfRadioRowSelection",
                "hozAlign": "center",
                "headerSort": false,
                "width": 50
            },*/
      { title: "ID", field: "id" },
      { title: "Number", field: "number" /*, headerFilter: 'input'*/ },
      { title: "UserID", field: "upn" },
      { title: "Name", field: "name" /*, headerFilter: 'input'*/ },
      { title: "TelNr", field: "phone" },
      { title: "Address", field: "address" },
      { title: "Org Unit", field: "orgUnit" },
      {
        title: "Status",
        field: "status",
        //formatter: selectFormatter
        // built in editor
        editor: "list",
        editorParams: {
          values: ["check", "del", "new"],
        },
      },
      { title: "Created at", field: "createTs" },
      { title: "Created by", field: "createUser" },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };

    getLastImport();
  }, []);

  function rowSelectionChanged(row: any) {
    //const selected: EntityModelRelationshipManagerDto = row.detail.data[0];
    //setSelected(selected);
  }

  function refreshTable(event: any) {
    dataTable.current!.options = { ...dataTable.current!.options };
  }

  function updateAllRelationshipManager() {
    setRelManagerFetching(true);
    authorizedCall(
      RelationshipManagerControllerService.updateAllRelationshipManagers,
      {}
    )
      .then(() => {
        setRelManagerFetching(false);
        getLastImport();
        dataTable.current!.options = { ...dataTable.current!.options };
      })
      .catch((error) => {
        /*do nothing*/
      })
      .finally(() => {
        //setRelManagerFetching(false);
      });
  }

  function getLastImport() {
    setRelManagerFetching(true);
    authorizedCall(
      RelationshipManagerControllerService.getLastRelationshipManagersImport,
      {}
    )
      .then((resp: Array<EntityModelAuditDto>) => {
        setLastImport(resp[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //setRelManagerFetching(false);
      });
  }

  console.log(
    "RENDER - ViewRelationshipManager [relManagerFetching:",
    relManagerFetching,
    "]"
  );

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        {
          name: "View Relationship Manager",
          route: "/view-reéationship-manager",
        },
      ]}
    >
      <div className="content p-4 bg-white">
        <div style={{ borderBottom: "1px solid #ddd" }}>
          <div className="flex flex-row pb-6">
            <div className="basis-1/2">
              <p
                className="heading--two text-xl"
                data-testid="view-relationship-manager-pagetitle"
              >
                View Relationship Manager
              </p>
            </div>
            <div className="basis-1/4">
              <ZfSearchField
                placeholder="Search ..."
                onInputChange={(e) => {
                  refreshTable(e);
                }}
                ref={searchFilter}
              ></ZfSearchField>
            </div>

            <div className="basis-1/4 flex flex-row-reverse">
              <ZfTextButton
                disabled={relManagerFetching === true}
                onClick={updateAllRelationshipManager}
              >
                Update
              </ZfTextButton>
              <ZfIconButton
                hierarchy="secondary"
                icon="arrow_back"
                className="mr-2"
                onClick={() => navigate(`/`)}
              ></ZfIconButton>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-3">
          <label className="font-semibold">Last import by:</label>
          <label className="font-light pl-2">{lastImport?.createUser}</label>
          <label className="font-semibold ml-2">at:</label>
          <label className="font-light pl-2">{lastImport?.createTs}</label>
        </div>
        {error && <p className="error">Error in loading data</p>}
        {relManagerFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) =>
            tableBuiltHandler(event, setRelManagerFetching, cellEditedHandler)
          }
          onTableRowSelectionChanged={rowSelectionChanged}
          className="mt-5"
          data-testid="vrm-data-table"
          style={{
            pointerEvents: relManagerFetching && "none",
            opacity: relManagerFetching && 0.7,
          }}
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};

export default ViewRelationshipManager;
