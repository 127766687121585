import { useEffect, useRef, useState } from "react";
import { ZfIconButton, ZfLoadingSpinner, ZfTable } from "@ccx/zafire-react";
import { AuthenticatedLayout } from "../common/components/AuthenticatedLayout";
import { useNavigate } from "react-router-dom";
import {
  tableBuiltHandler,
  zfTableDefaultOptions,
} from "../common/ZfTable/zftable-configuration";
import authorizedCall from "../../utils/authorizedCallUtils";
import { NostroControllerService } from "../../openapi";

const OtherNostroViews = () => {
  const navigate = useNavigate();

  const dataTable = useRef<HTMLZfTableElement>(null);

  const [error, setError] = useState(false);

  const [isFetching, setIsFetching] = useState<boolean>(true);

  /**
   *
   * @param url the url of the request
   * @param config the ajaxConfig object
   * @param params the ajaxParams object
   * @returns
   */
  async function dataTableApiCall(url: any, config: any, params: any) {
    const apiParams = {
      page: params.page,
      size: params.size,
    };

    setIsFetching(true);
    return authorizedCall(
      NostroControllerService.getOtherNostroViews,
      apiParams,
      true
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  /**
   *
   * First configuration after renderization
   */
  useEffect(() => {
    const defaultOptions = zfTableDefaultOptions(setError);

    const TABLE_COLUMNS = [
      { title: "Name", field: "title", headerSort: false },
      { title: "Record Count", field: "count", headerSort: false },
      {
        title: "Action",
        field: "route",
        headerSort: false,
        hozAlign: "right",
        formatter: function (cell: any, _: any, onRendered: any) {
          const eventHandler = () =>
            cell
              .getElement()
              .querySelector("zf-text-button")
              .addEventListener("click", () => navigate(cell.getValue()));
          onRendered(eventHandler);
          return `<zf-text-button size="small"/>Navigate</zf-text-button>`;
        },
      },
    ];

    const actionColumns: [] = [];

    setError(false);
    dataTable.current!.options = {
      columns: [...TABLE_COLUMNS, ...actionColumns],
      data: [],
      ...defaultOptions,
      ajaxRequestFunc: dataTableApiCall,
    };
  }, [navigate]);

  console.log("RENDER - AccountCategories [isFetching:", isFetching, "]");

  return (
    <AuthenticatedLayout
      breadcrumbList={[
        { name: "ACTION PANEL", route: "/" },
        { name: "Other views", route: "/other-views" },
      ]}
    >
      <div className="content p-4 bg-white">
        <div
          className="flex flex-row pb-6"
          style={{ borderBottom: "1px solid #ddd" }}
        >
          <div className="basis-1/2">
            <p
              className="heading--two text-xl"
              data-testid="view-acc-cat-pagetitle"
            >
              Other Views
            </p>
          </div>
          <div className="basis-1/4"></div>
          <div className="basis-1/4 flex flex-row">
            <ZfIconButton
              hierarchy="secondary"
              icon="arrow_back"
              className="mr-1"
              onClick={() => navigate(`/`)}
            ></ZfIconButton>
          </div>
        </div>
        {error && <p className="error">Error in loading data</p>}

        {isFetching && (
          <ZfLoadingSpinner
            size="large"
            color="primary"
            type="infinite"
            style={{
              position: "absolute",
              left: "50%",
              right: "50%",
              zIndex: "100",
              top: "35%",
            }}
            data-testid="spinner"
          ></ZfLoadingSpinner>
        )}

        <ZfTable
          ref={dataTable}
          onTableBuilt={(event) => tableBuiltHandler(event, setIsFetching)}
          className="mt-5 table-striped"
          style={{
            pointerEvents: isFetching && "none",
            opacity: isFetching && 0.7,
          }}
          data-testid="ov-data-table"
        ></ZfTable>
      </div>
    </AuthenticatedLayout>
  );
};

export default OtherNostroViews;
