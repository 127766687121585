import { FC, PropsWithChildren, useEffect, useState } from "react";

import env, { load } from "../../../../config/env";
import { OpenAPI } from "../../../../openapi";

export const LoadConfigRender: FC<PropsWithChildren> = (props) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    load().then(() => {
      setHasLoaded(true);
      OpenAPI.BASE = env.REACT_APP_API_URL;
      //alert('env loaded');
    });
  });

  console.log("RENDER - LoadConfigRender");
  if (!hasLoaded) {
    return <div data-testid="not-loaded" />;
  } else {
    return <>{props.children}</>;
  }
};
