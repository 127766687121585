//import "@ccx/foundations/variables.css";
//import "@ccx/foundations/font-faces.css";
//import { defineCustomElements, ZfAppContainer } from "@ccx/zafire-react";

import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import { setupStore } from "./store/store";

import Oidc from "./components/auth/Oidc";
import { LoadConfigRender } from "./components/common/components/LoadConfigRender";
import { Provider } from "react-redux";
import App from "./App";
import ZfConfig from "./components/common/ZfConfig";

//defineCustomElements();

//const isEnabled = oidcConfiguration.origin === document.location.origin;

async function enableMocking() {
  //console.log("process.env", process.env);

  if (process.env.REACT_APP_MOCK_API !== "1") {
    return;
  }

  console.log("Mock starting...");

  const { worker } = await require("./mocks/browser");

  return worker.start();
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  console.log("RENDER - index");
  root.render(
    <StrictMode>
      <ZfConfig>
        <Provider store={setupStore()}>
          <LoadConfigRender>
            <Oidc>
              <App />
            </Oidc>
          </LoadConfigRender>
        </Provider>
      </ZfConfig>
    </StrictMode>
  );
});
